import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col} from 'antd';
import {Check, LocalShipping} from '@styled-icons/material-outlined';
import {Text, Heading, Row} from '../../Widgets';
import Image from '../../Components/Image';
import {dateUtil} from '../../Utils';
import {calculateEstimatedShipmentDate} from '../../Utils/requireDayUtil';
import useOrderDisplayState from '../../hooks/use-order-display-state';
import useCartItemTable from '../../hooks/use-cart-item-table';
import Block from '../../Components/Checkout/Block';
import {PAYMENT_INFO} from '../../Constants/Payment';
import PaymentInfo from '../../Components/Checkout/PaymentInfo';
const config = require('../../data.json');

export default function TopSection(props) {
  const {order, id, onUpdate} = props;
  let orderState = useOrderDisplayState({
    display_state: order.display_state,
    payment_type: order.payment_type,
    payment_subtype: order.payment_subtype,
    order_type: order.order_type,
    logistics: order.logistics,
    payment_status: order.payment_status,
    remittance_account: order.remittance_account,
    remittance_number: order.remittance_number,
    remitted_time: order.remitted_time,
  });
  const {cart} = useCartItemTable({order});
  const [requireDate, setRequireDate] = useState('');

  useEffect(() => {
    const maxRequireDateStr = calculateEstimatedShipmentDate({
      order: order,
      cartItems: cart?.items,
    });
    setRequireDate(maxRequireDateStr);
  }, [order]);

  if (!order) {
    return null;
  }

  const completeTime = new Date(order.complete_time);
  const now = new Date();
  const diffDays = Math.floor((now - completeTime) / (1000 * 60 * 60 * 24));

  const renderOrderStatus = () => {
    const completedStates = [
      'completed',
      'ass_apply',
      'refunding',
      'refunded',
      'voided',
    ];

    if (completedStates.includes(order.display_state)) {
      return (
        <Fragment>
          <Check size={24} color="#1FA689" style={{marginRight: 4}} />
          訂單已完成
          {order.display_state === 'completed' && diffDays < 3 && (
            <span style={{fontWeight: 700, marginLeft: 8}}>{requireDate}</span>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <LocalShipping size={24} color="#1FA689" style={{marginRight: 8}} />
          預計出貨日期
          <span style={{fontWeight: 700, marginLeft: 8}}>{requireDate}</span>
        </Fragment>
      );
    }
  };

  const isOffline = order.payment_type === 'offline';
  const isSmallScreen = window.innerWidth < config.breakpoints.sm;
  const remittanceCompleted =
    order.remittance_account && order.remittance_number && order.remitted_time;

  const renderStatusSubtitle = () => {
    if (isOffline && isSmallScreen && remittanceCompleted) {
      return null;
    }
    if (isOffline && remittanceCompleted) {
      return orderState.subtitle;
    }
    return `，${orderState.subtitle}`;
  };

  return (
    <Fragment>
      <OrderInfo>
        <div>
          訂單編號：
          <span>{order.display_id}</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
          <div style={{borderRight: '1px solid #717071', paddingRight: 17}}>
            下單日期
            <span style={{color: '#000', fontWeight: 700, marginLeft: 8}}>
              {`${dateUtil.format(order.created, 'YYYY/MM/DD')}`}
            </span>
          </div>
          <div style={{paddingLeft: 17, color: '#1FA689'}}>
            {renderOrderStatus()}
          </div>
        </div>
      </OrderInfo>
      <Block
        title="付款資訊"
        bg={order.payment_status === 'success' ? config.colors.main : '#b5b5b5'}
        color="white">
        <OrderStatus>
          <Image
            src={orderState.icon}
            style={{width: 56}}
            alt="order state image"
          />
          <div>
            {orderState.title}
            {renderStatusSubtitle()}
          </div>
          {order.payment_status !== 'success' && (
            <Row style={{width: '100%', marginLeft: 0}}>
              <PaymentInfo
                type={
                  order.payment_type === 'payuni'
                    ? order.payment_subtype
                    : order.payment_type
                }
                order={order}
                id={id}
                onUpdate={onUpdate}
              />
            </Row>
          )}
        </OrderStatus>
      </Block>
    </Fragment>
  );
}

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 32px;
  > :first-child {
    font-size: 20px;
    font-weight: 700;
    color: #231815;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const OrderStatus = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    font-size: 18px;
    font-weight: 500;
    color: ${config.colors.main};
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    > div {
      text-align: center;
      margin-left: 0;
    }
  }
`;
