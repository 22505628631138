import React, {useEffect, useState, useContext, useMemo} from 'react';
import {Context} from '../AppContext';
import {DELIVERY_TYPE} from '../dictionary';

export default function useLogisticData({order, logistics}) {
  const [records, setRecords] = useState(logistics || []); // logistics maybe undefined
  const isCreated = records.length > 0;
  const cart = useMemo(() => {
    try {
      return JSON.parse(order.cart);
    } catch (error) {}
  }, [order]);

  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      if (order && !logistics) {
        try {
          let resp = await app.actions.getLogistics({
            order: order.id,
          });
          setRecords(resp.results);
        } catch (err) {
          console.warn(err);
        }
      }
    })();
  }, [order, app?.actions, logistics]);

  const processedRecords = useMemo(() => {
    if (isCreated) {
      return records.map((record) => ({
        ...record,
        sender_address: record.private_address || record.sender_address,
        ...(['ezship', 'payuni', 'xdelivery'].includes(record.logistics_type) &&
        cart?.config?.deliveryConfig
          ? {
              st_state: cart.config.deliveryConfig.st_state,
              st_code: cart.config.deliveryConfig.st_code,
              rstore_name: cart.config.deliveryConfig.rstore_name,
              rstore_tel: cart.config.deliveryConfig.rstore_tel,
              rstore_addr: cart.config.deliveryConfig.rstore_addr,
            }
          : {}),
      }));
    } else if (cart && cart.config) {
      // 未產生出貨單，以order config代替
      return [
        {
          id: null,
          logistics_type:
            cart.config.deliveryConfig?.delivery_type ||
            Object.keys(DELIVERY_TYPE)[0],
          logistics_status: '',
          tracking_number: '',
          is_delivery_private: false,
          sender_name: '',
          sender_phone: '',
          sender_tel_ext: '',
          sender_zip: '',
          sender_city: null,
          sender_district: null,
          sender_address: '',
          receiver_name: '',
          receiver_phone: '',
          receiver_tel: '',
          receiver_tel_ext: '',
          receiver_phone_spare: '',
          zip_code: cart.config.deliveryConfig?.zip_code || '',
          receiver_city: null,
          receiver_district: null,
          receiver_address: '',
          logistics_note: cart.config.deliveryConfig?.delivery_note || '',
          shipping_time: null,
          updated: '',
          ...cart.config.deliveryConfig,
        },
      ];
    }
    return [];
  }, [isCreated, records, cart]);

  return {
    logistics: processedRecords,
    isCreated,
  };
}
